// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Nullable, StudyAreaSnapshot } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useStudyAreaSnapshotStore =
  defineStore('study-area-snapshot', () => {
    // fetch repositories
    const { $studyAreaSnapshots } = useNuxtApp()

    // state
    const studyAreaSnapshot = ref<Nullable<StudyAreaSnapshot>>(null)
    const studyAreaSnapshots = ref<StudyAreaSnapshot[]>([])

    // actions
    async function create (
      payload?: Record<string, any>,
      config?: NitroFetchOptions<string>
    ) {
      const { studyAreaSnapshot: _studyAreaSnapshot } =
        await $studyAreaSnapshots
          .create<{ studyAreaSnapshot: StudyAreaSnapshot }>(
            { study_area_snapshot: payload },
            config
          )
      studyAreaSnapshots.value =
        [...studyAreaSnapshots.value, _studyAreaSnapshot]
      studyAreaSnapshot.value = _studyAreaSnapshot
      return studyAreaSnapshot
    }

    return {
      studyAreaSnapshot,
      studyAreaSnapshots,
      create
    }
  })
