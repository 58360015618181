// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { PropertyRecord, Nullable } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const usePropertyRecordStore = defineStore('property-records', () => {
  // fetchRepositories
  const { $propertyRecords } = useNuxtApp()

  // state
  const propertyRecord = ref<Nullable<PropertyRecord>>(null)
  const propertyRecords = ref<PropertyRecord[]>([])

  // actions
  async function create (
    payload?: Record<string, any>,
    config?: NitroFetchOptions<string>
  ) {
    const { propertyRecord: _propertyRecord } =
      await $propertyRecords.create<{ propertyRecord: PropertyRecord }>(
        payload,
        config
      )
    propertyRecord.value = _propertyRecord
    propertyRecords.value = [...propertyRecords.value, _propertyRecord]

    return propertyRecord
  }

  async function deletePropertyRecord (
    id: number,
    payload: { [key: string]: any } = {},
    config: NitroFetchOptions<string> = {}
  ) {
    await $propertyRecords.delete(id, payload, config)
    if (propertyRecords.value.length) {
      propertyRecords.value = propertyRecords.value.filter((propertyRecord) => {
        return propertyRecord.id !== id
      })
    }
  }

  return {
    propertyRecord,
    propertyRecords,
    create,
    deletePropertyRecord
  }
})
